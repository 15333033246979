import formatHtml from 'sanitize-html';
const myServerEnv = process.env.REACT_APP_SERVER_ENV ? process.env.REACT_APP_SERVER_ENV : 'uat';

export const printEDCLog = (...params: any[]): void => {
  if (myServerEnv.toLowerCase() === 'uat') {
    console.log(...params);
  }
};

export const isJson = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const sanitizeHtml = (unsafeHtml: string) => {
  return formatHtml(unsafeHtml, {
    allowedTags: ['a', 'del', 'div', 'ins', 'span', 'strong', 'p', 'ol', 'ul', 'li', 'br', 'b'],
    allowedAttributes: {
      a: ['href', 'target', 'rel'],
      span: ['class'],
      div: ['class'],
      strong: ['class'],
      p: ['class'],
    },
  });
};
