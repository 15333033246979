import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Autocomplete from 'react-autocomplete';
import { UncontrolledTooltip } from 'reactstrap';
import axios, { AxiosError } from 'axios';
import debounce from 'debounce';

// Import menuDropdown
// import LanguageDropdown from '../Common/TopbarDropdown/LanguageDropdown';
import ProfileMenu from '../Common/TopbarDropdown/ProfileMenu';
import SearchResultBox from '../Common/TopbarDropdown/SearchResultBox';

import { useData } from '../../context/DataContext';
import { ReactComponent as BarsIcon } from '../../assets/images/icons/burger.svg';
import { ReactComponent as GearIcon } from '../../assets/images/icons/gear.svg';
import { deleteBookmark, searchParticipant } from '../../services';
import { myProjectName } from '../../constants';

const myServerEnv = process.env.REACT_APP_SERVER_ENV ? process.env.REACT_APP_SERVER_ENV : 'uat';

const Header = () => {
  const {
    leftSideBarType,
    setLeftSideBarType,
    showGearModal,
    setShowGearModal,
    bookmarked,
    setBookmark,
    setShowBookmarkModal,
    search,
    setSearch,
  } = useData();
  const location = useLocation();
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [showSearchResultBox, setShowSearchResultBox] = useState(false);

  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const tToggle = () => {
    if (leftSideBarType === 'default') {
      setLeftSideBarType('menu-closed');
    } else if (['condensed', 'menu-opened', 'menu-closed'].includes(leftSideBarType)) {
      setLeftSideBarType('default');
    }
  };

  const toggleLogicValuesModal = () => {
    setShowGearModal(!showGearModal);
  };

  const toggleBookmark = () => {
    if (!bookmarked) {
      setShowBookmarkModal(true);
      return;
    } else {
      deleteBookmark(location.pathname, myProjectName.toLowerCase());
    }
    setBookmark(!bookmarked);
  };

  const handleChange = (e: any) => {
    setSearch(e.target.value);
    if (e.target.value.length < 2) {
      return;
    }
    searchDelayed(e.target.value);
  };

  const getSuggestionsData = async (queryStr: string) => {
    try {
      if (!queryStr) {
        setOptions([]);
        return;
      }
      const optionsData = await searchParticipant(queryStr);

      console.log(optionsData);
      if (optionsData) {
        if (optionsData.data?.length) {
          setOptions(optionsData.data);
        } else {
          setOptions([]);
        }
        setShowSearchResultBox(true);
      }
    } catch (err: any | AxiosError) {
      console.log(err.response.data);
    }
  };

  const searchDelayed = debounce(getSuggestionsData, 500);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="ml-3 btn btn-sm font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <BarsIcon />
            </button>
            <button
              type="button"
              onClick={() => {
                toggleLogicValuesModal();
              }}
              className="ml-1 btn btn-sm font-size-24 header-item waves-effect"
              id="gear-btn"
            >
              <GearIcon />
            </button>
            <button
              type="button"
              onClick={() => {
                toggleBookmark();
              }}
              className="ml-1 btn btn-sm font-size-24 header-item waves-effect"
              id="bookmark-btn"
            >
              <div className={`${bookmarked ? '' : 'hide'}`} id="tooltip-bookmarked">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <UncontrolledTooltip placement="bottom" target={`tooltip-bookmarked`}>
                Already bookmarked
              </UncontrolledTooltip>
              <div className={`${bookmarked ? 'hide' : ''}`} id="tooltip-bookmark">
                <i className="fa-regular fa-bookmark"></i>
              </div>
              <UncontrolledTooltip placement="bottom" target={`tooltip-bookmark`}>
                Bookmark this page
              </UncontrolledTooltip>
            </button>
            {myServerEnv == 'uat' && (
              <div className="p-2 d-flex justify-content-center align-items-center text-white cursor-pointer">
                UAT
              </div>
            )}
          </div>
          <div className="d-flex">
            <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={'Search...'}
                  onChange={handleChange}
                  value={search}
                />
                <span className="bx bx-search-alt"></span>
              </div>
              {showSearchResultBox && (
                <SearchResultBox
                  options={options}
                  setSearch={setSearch}
                  setShowSearchResultBox={setShowSearchResultBox}
                />
              )}
            </form>
            {/* <LanguageDropdown /> */}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
