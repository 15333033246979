import React, { useEffect, useRef, useState } from 'react';
import { Alert, Spinner, Label } from 'reactstrap';

import { useParams } from 'react-router-dom';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { DataServices } from '@lainaedge/platformshared';
import { ParticipantAuthResult } from '@lainaedge/platformshared/src/types/ParticipantAuthResult';

import Layout from '../../components/StudyNonAuthLayout';
import { useAuth } from '../../context/AuthContext';

import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const dataService = DataServices.instance();

interface ISetPasswordFormFields {
  password: string;
  password_confirmation: string;
}

/**
 * Participant/SetPassword component.
 *
 * @remarks
 * Page where participant sets the password for the first time
 *
 * @component SetPassword
 * @category Page
 */
const SetPassword = (): JSX.Element => {
  const { pid } = useParams() as { pid: string };
  const { setUser, setUserType } = useAuth();
  const [isSubmitting, SetIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [pwdError, setPwdError] = useState<string>('');
  // const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);
  const isMagicLinkSent = false;

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  // handleValidSubmit
  async function handleValidSubmit(values: ISetPasswordFormFields) {
    SetIsSubmitting(true);

    setPwdError('');
    if (values.password !== values.password_confirmation) {
      setPwdError('Confirm Password does not match');
      SetIsSubmitting(false);
      return;
    }

    const result: ParticipantAuthResult = await dataService.participantSetPassword(
      pid,
      values.password,
    );

    if (result.success) {
      const result1: ParticipantAuthResult = await dataService.authParticipant(
        pid,
        values.password,
      );
      if (result1.success) {
        const userObj = {
          username: pid,
          email: pid,
          userType: 'participant',
          token: result1.authToken!,
        };
        cookies.set('authUser', userObj, { path: '/' });
        cookies.set('token', result1.authToken, { path: '/' });
        cookies.set('type', 'participant', { path: '/' });
        setUser(userObj);
        setUserType('participant');
        window.location.href = '/page/participant_start/' + pid;
      } else {
        setErrorMessage(result1.errorMessage!);
      }
    } else {
      setErrorMessage(result.errorMessage!);
    }

    if (!mounted.current) return;
    SetIsSubmitting(false);
  }

  return (
    <Layout>
      <Formik
        enableReinitialize={true}
        initialValues={{
          password: '',
          password_confirmation: '',
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required('Enter Password'),
          password_confirmation: Yup.string().required('Enter Password Confirmation'),
        })}
        onSubmit={(values) => {
          handleValidSubmit(values);
        }}
      >
        {({ errors, touched }) => (
          <Form className="form-horizontal">
            {isMagicLinkSent && (
              <>
                <h3 className="text-center mb-5">Check your email!</h3>
                <div className="mt-1 text-center">
                  <span>We emailed a magic link to testemail@test.com</span>
                  <br />
                  <span>Click the link to log in or sign up</span>
                </div>
              </>
            )}

            {!isMagicLinkSent && (
              <>
                <h3 className="text-center mb-5">Set Password</h3>
                {errorMessage && errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}

                {pwdError && pwdError ? (
                  <Alert color="danger" style={{ marginTop: '13px' }}>
                    {pwdError}
                  </Alert>
                ) : null}

                <div className="mb-3">
                  <Label for="password" className="form-label">
                    Set a password to protect your account. your password should be at least 6
                    letters or numbers long.
                  </Label>
                  <Field
                    name="password"
                    type="password"
                    id="password"
                    required
                    placeholder="Password"
                    className={
                      'form-control' + (errors.password && touched.password ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage name="password" component="div" className="invalid-feedback" />
                </div>

                <div className="mb-3">
                  <Field
                    name="password_confirmation"
                    id="password_confirmation"
                    type="password"
                    required
                    placeholder="Retype to confirm"
                    className={
                      'form-control' +
                      (errors.password_confirmation && touched.password_confirmation
                        ? ' is-invalid'
                        : '')
                    }
                  />
                  <ErrorMessage
                    name="password_confirmation"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="mt-3">
                  <button
                    className="btn btn-login btn-block waves-effect waves-light save-btn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Set Password
                    {isSubmitting && <Spinner className="spinner" />}
                  </button>
                </div>

                {/* <div className="mt-1 text-center">
                  <span>Or</span>
                  <h5 className="mt-3">Long password? Hard to type?</h5>
                  <span>
                    We can email you a magic link so you can sign in without having to type your
                    password
                  </span>
                </div>

                <div className="mt-3">
                  <button
                    className="btn btn-login btn-block waves-effect waves-light save-btn"
                    type="button"
                    onClick={handleLoginWithMagicLink}
                    disabled={isSubmitting}
                  >
                    Login with a magic link
                  </button>
                </div> */}
              </>
            )}
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default SetPassword;
