import React, { useState } from 'react';
/**
 * Imports specific from Laina Shared Platform
 */
import { DataTools } from '@lainaedge/platformshared';

import { Link } from 'react-router-dom';
import { Row, Col, CardBody, Card, Alert, Container, Label, UncontrolledTooltip } from 'reactstrap';
import packageJson from '../../package.json';

import toastr from 'toastr';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// import images
import vector2 from '../assets/images/vector2.png';
import logo from '../assets/images/logo-laina.png';

import { useData } from '../context/DataContext';
import { useAuth } from '../context/AuthContext';
import { myProjectName } from '../constants';

const myServerEnv = process.env.REACT_APP_SERVER_ENV ? process.env.REACT_APP_SERVER_ENV : 'uat';

const DEMO_PID_PASSWORD = 'laina1234';

/**
 * CreateMagicLink component.
 *
 * @remarks
 * Page where you can create a new magic link
 *
 * @component CreateMagicLink
 * @category Page
 */
const CreateMagicLink = (): JSX.Element => {
  const { versionInfo, dashTitle } = useData();
  const { error } = useAuth();
  const [magicLink, SetMagicLink] = useState('');

  // handleValidSubmit
  async function handleValidSubmit(values: { participantId: string; targetPage: string }) {
    const testValue2 = {
      Pid: values.participantId, // '9995-594-362'
      Password: DEMO_PID_PASSWORD,
      Page: values.targetPage, // 'page/participant_start/9995-594-362'
    };
    const stringValue = DataTools.encodeObjectCompressed(testValue2);

    SetMagicLink(window.location.origin + `/system/magicPage?code=${stringValue}`);
  }

  const copyTextToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toastr.success('Magic Link Copied to Clipboard', 'Success');
  };

  const titleParts = dashTitle.split('(');

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="login-page account-pages pt-sm-3">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-header">
                  <div className="title">
                    <img src={logo} alt="" className="img-fluid" />
                  </div>
                  <img src={vector2} alt="" className="img-fluid" />
                </div>
                <div className="card-body pb-0">
                  <div className="text-darkblue p-2">
                    <h5 className="text-darkblue">{titleParts[0]}</h5>
                    <p className="mb-0">{titleParts.length === 2 && '(' + titleParts[1]}</p>
                  </div>
                </div>
                <CardBody className="">
                  <div className="p-2">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        participantId: '',
                        targetPage: '',
                      }}
                      validationSchema={Yup.object().shape({
                        participantId: Yup.string().required('Enter Participant ID'),
                        targetPage: Yup.string().required('Enter Target Page Link'),
                      })}
                      onSubmit={(values) => {
                        handleValidSubmit(values);
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form className="form-horizontal">
                          {error && error ? <Alert color="danger">{error}</Alert> : null}
                          <div className="form-group">
                            <Label for="participantId" className="form-label">
                              Participant ID
                            </Label>
                            <Field
                              name="participantId"
                              id="participantId"
                              type="input"
                              required
                              placeholder="Enter Participant ID"
                              className={
                                'form-control' +
                                (errors.participantId && touched.participantId ? ' is-invalid' : '')
                              }
                            />
                            <ErrorMessage
                              name="participantId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group">
                            <Label for="targetPage" className="form-label">
                              Target Page Link
                            </Label>
                            <Field
                              name="targetPage"
                              id="targetPage"
                              type="input"
                              required
                              placeholder="Enter Target Page Link"
                              className={
                                'form-control' +
                                (errors.targetPage && touched.targetPage ? ' is-invalid' : '')
                              }
                            />
                            <ErrorMessage
                              name="targetPage"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="mt-3">
                            <button
                              className="btn btn-login btn-block waves-effect waves-light save-btn"
                              type="submit"
                            >
                              Create Link
                            </button>
                          </div>

                          {magicLink && (
                            <div className="mt-3">
                              <span
                                role="button"
                                id="magic-link"
                                onClick={() => copyTextToClipboard(magicLink)}
                              >
                                {magicLink}
                              </span>
                              <UncontrolledTooltip placement="top" target={`magic-link`}>
                                You can copy the link by clicking on it
                              </UncontrolledTooltip>
                            </div>
                          )}
                          {myServerEnv === 'uat' && (
                            <div className="mt-3 login-page-small">
                              This is the User Acceptance Test (UAT) Environment. If you are at the
                              site or looking for production data{' '}
                              <a
                                className="login-notice"
                                href={'https://prod.' + myProjectName + '.trial.lainaent.com/'}
                              >
                                Click Here.
                              </a>
                            </div>
                          )}

                          {myServerEnv === 'prod' && (
                            <div className="mt-3">
                              This is the Production Server for {dashTitle}
                            </div>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="text-center login-page-small">
                CPS : {versionInfo?.clientPlatformSharedVersion}, SPS :{' '}
                {versionInfo?.serverPlatformSharedVersion}, UI : {packageJson.version}, TDD:{' '}
                {versionInfo?.serverTDDVersion}, V: {versionInfo?.version}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateMagicLink;
