import React, { Component } from 'react';

/**
 * Visual primitives for the component age. Use the best bits of ES6 and CSS to style your apps without stress
 *
 * @remarks
 *
 * Take a look at official documentation:
 * {@link https://reactstrap.github.io/} or
 * {@link https://github.com/reactstrap/reactstrap}
 * @packageDocumentation
 */
import styled from 'styled-components';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepList } from '@lainaedge/platformshared';
// import LockOpen from  '../../assets/images/lock-open.png';
// import LockClosed from  '../../assets/images/lock-closed.png';
import { RenderHelper } from '../RenderHelper';
import { isJson } from '../../helpers';

const ListItem = styled.tr`
  cursor: pointer;
`;

const NoteListItem = styled.li`
  cursor: pointer;
`;

/**
 * Props for [[`PageListItem`]] component
 */
export interface PageListItemProps {
  /** Step data */
  step: StepList;
  /** Task data */
  item: any;
  /** Used for redirecting. */
  renderer: RenderHelper;
}

/**
 * PageListItem component
 *
 * @component PageListItem
 * @category PageElements
 */
export default class PageListItem extends Component<PageListItemProps> {
  constructor(props: PageListItemProps) {
    super(props);

    this.state = {};
  }

  /**
   * NoteListItem click hanlder, used to redirect.
   */
  handleClick = async () => {
    const item = this.props.item;
    //window.location.href = item.click!;

    this.props.renderer.navigate(item.click);
  };

  /**
   * Used to prevent the onChange event.
   *
   * @param e - Event object
   * @returns Promise
   */
  handleFormLock = async (e: any) => {
    e.stopPropagation();
  };

  /**
   * Renders PageListItem class component.
   */
  public render(): JSX.Element {
    const item = this.props.item;

    const style = this.props.step.listOptions.style;

    const taskClass = item.TaskStatus === 'Pending' ? 'bg-danger' : 'bg-primary';

    // console.log(style, item);

    if (style === 'NewTaskList') {
      return (
        <ListItem onClick={this.handleClick}>
          <td className={taskClass} style={{ width: '5px', padding: 0, margin: 0 }}></td>
          <td className={`noborder`}></td>
          <td className="text-left">
            <span>
              <i className={item.TaskIcon + ' font-size-24 mr-2'}></i>
            </span>
            <span className={`text-wrap font-size-20 mb-0`}>{item.TaskTitle}</span>
          </td>
          <td className="text-right">
            <span className={`font-size-14 pending`}>
              <i
                className={
                  item.TaskStatus === 'Pending'
                    ? 'task-alert fas fa-loader mr-1'
                    : 'task-success fa fa-check mr-1'
                }
              ></i>
              <span className="mobile-hide">{item.TaskStatus}</span>
            </span>
            <span className="border-line"></span>
            <span className={`font-size-14 lock-status`}>
              <i
                className={
                  item.lockstatus === 'true'
                    ? 'task-alert far fa-lock mr-1'
                    : 'task-success far fa-unlock mr-1'
                }
              ></i>
              <span className="mobile-hide">
                {item.lockstatus === 'true' ? 'Locked' : 'Unlocked'}
              </span>
            </span>
            <span className="border-line"></span>
            <span className={`font-size-14 review-status`}>
              <i
                className={
                  item.reviewstatus === 'true'
                    ? 'task-success far fa-eye mr-1'
                    : 'task-alert far fa-eye-slash mr-1'
                }
              ></i>
              <span className="mobile-hide">
                {item.reviewstatus === 'true' ? 'Reviewed' : 'Not yet reviewed'}
              </span>
            </span>
          </td>
        </ListItem>
      );
    }

    if (item.style === 'Note List:') {
      return (
        <NoteListItem className="event-list" onClick={this.handleClick}>
          <div className="event-timeline-dot">
            <i className="bx bx-right-arrow-circle font-size-18"></i>
          </div>
          <div className="media">
            <div className="mr-3">
              <h5 className="font-size-14">
                {item.TaskStatus}{' '}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2"></i>
              </h5>
            </div>
            <div className="media-body">
              <div>{item.TaskTitle}</div>
            </div>
          </div>
        </NoteListItem>
      );
    }

    const badgeClass =
      item.TaskStatus === 'Pending' ? 'badge badge-warning' : 'badge badge-primary';

    return (
      <ListItem onClick={this.handleClick}>
        <td className="text-right" style={{ width: 24 }}>
          <span>
            <i className={item.TaskIcon + ' font-size-24'} />
          </span>
        </td>
        <td>
          <h5 className="text-wrap">
            {/* {style == 'Medications List' && isJson(item.TaskTitle)
              ? JSON.parse(item.TaskTitle)?.name
              : item.TaskTitle} */}
            {isJson(item.TaskTitle) ? JSON.parse(item.TaskTitle)?.name : item.TaskTitle}
          </h5>
          <p className="text-muted mb-0">{item.TaskSubTitle}</p>
        </td>
        <td className="text-right">
          <span className={badgeClass + ' font-size-14'}>{item.TaskStatus}</span>
        </td>
        <td>
          <span> &gt; </span>
        </td>
        {!item.hidelocks && (
          <td>
            <i
              className={
                item.lockstatus ? 'fas fa-lock font-size-18' : 'fas fa-lock-open font-size-18'
              }
            />
          </td>
        )}
        {/* <td><img src={item.lockstatus ? LockOpen : LockClosed} alt="" className="lock-icon" /></td> */}
      </ListItem>
    );
  }
}
