import React, { useEffect, useRef, useState } from 'react';
import { Alert, Spinner } from 'reactstrap';

import { Link, useParams } from 'react-router-dom';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { DataServices } from '@lainaedge/platformshared';
import { ParticipantAuthResult } from '@lainaedge/platformshared/src/types/ParticipantAuthResult';

import Layout from '../../components/StudyNonAuthLayout';

import { Cookies } from 'react-cookie';
import { useAuth } from '../../context/AuthContext';

const cookies = new Cookies();

interface IEnterPasswordFormFields {
  password: string;
}

const dataService = DataServices.instance();

/**
 * Participant/EnterPassword component.
 *
 * @remarks
 * Page where participant enters password to log in
 *
 * @component EnterPassword
 * @category Page
 */
const EnterPassword = (): JSX.Element => {
  const { pid } = useParams() as { pid: string };
  const { setUser, setUserType } = useAuth();
  const [isSubmitting, SetIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);
  const isMagicLinkSent = false;

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  // handleValidSubmit
  async function handleValidSubmit(values: IEnterPasswordFormFields) {
    SetIsSubmitting(true);
    const result: ParticipantAuthResult = await dataService.authParticipant(pid, values.password);

    if (result.success) {
      const userObj = {
        username: pid,
        email: pid,
        userType: 'participant',
        token: result.authToken!,
      };
      cookies.set('authUser', userObj, { path: '/' });
      cookies.set('token', result.authToken, { path: '/' });
      cookies.set('type', 'participant', { path: '/' });
      setUser(userObj);
      setUserType('participant');
      window.location.href = '/page/participant_start/' + pid;
    } else {
      setErrorMessage(result.errorMessage!);
    }

    if (!mounted.current) return;
    SetIsSubmitting(false);
  }

  // const handleLoginWithMagicLink = () => {
  //   setIsMagicLinkSent(true);
  // };

  return (
    <Layout showForgotPassword={true}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          password: '',
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required('Enter Password'),
        })}
        onSubmit={(values) => {
          handleValidSubmit(values);
        }}
      >
        {({ errors, touched }) => (
          <Form className="form-horizontal">
            {isMagicLinkSent && (
              <>
                <h3 className="text-center mb-5">Check your email!</h3>
                <div className="mt-1 text-center">
                  <span>We emailed a magic link to testemail@test.com</span>
                  <br />
                  <span>Click the link to log in or sign up</span>
                </div>
              </>
            )}

            {!isMagicLinkSent && (
              <>
                <h3 className="text-center mb-5">Enter Password</h3>
                {errorMessage && errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}

                <div className="form-group">
                  <Field
                    name="password"
                    id="password"
                    type="password"
                    required
                    placeholder="Password"
                    className={
                      'form-control' + (errors.password && touched.password ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage name="password" component="div" className="invalid-feedback" />
                </div>

                <div className="mt-3">
                  <button
                    className="btn btn-login btn-block waves-effect waves-light save-btn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Login
                    {isSubmitting && <Spinner className="spinner" />}
                  </button>
                </div>

                {/* <div className="mt-1 text-center">
                  <span>Or</span>
                  <h5 className="mt-3">Long password? Hard to type?</h5>
                  <span>
                    We can email you a magic link so you can sign in without having to type your
                    password
                  </span>
                </div>

                <div className="mt-3">
                  <button
                    className="btn btn-login btn-block waves-effect waves-light save-btn"
                    type="button"
                    onClick={handleLoginWithMagicLink}
                    disabled={isSubmitting}
                  >
                    Login with a magic link
                  </button>
                </div> */}
              </>
            )}

            <div className="mt-3">
              <Link
                className="btn btn-light-login btn-block waves-effect waves-light save-btn"
                to="/contact"
              >
                Contact Trial Support
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default EnterPassword;
