/**
 * Imports specific from Laina Shared Platform
 */
import { StepGroup } from '@lainaedge/platformshared';
import React, { Component } from 'react';

/**
 * Stateless React Components for Bootstrap 4.
 *
 * @remarks
 *
 * Library contains React Bootstrap 4 components that favor composition and control.
 * Take a look at official documentation:
 * {@link https://reactstrap.github.io/} or
 * {@link https://github.com/reactstrap/reactstrap}
 * @packageDocumentation
 */
import { Col, Row, Card, CardBody, CardTitle, CardText, CardHeader } from 'reactstrap';

/**
 * Props for [[`PageGroup`]] component
 */
export interface PageGroupProps {
  /** Array of components */
  components: JSX.Element[];
  /** Step data */
  step: StepGroup;
}

/**
 * PageGroup component
 *
 * @component PageGroup
 * @category PageElements
 */
export default class PageGroup extends Component<PageGroupProps> {
  constructor(props: PageGroupProps) {
    super(props);

    this.state = {};
  }

  /**
   * Renders PageGroup class component.
   */
  public render(): JSX.Element {
    const components = this.props.components;
    const step = this.props.step;

    if (step.options.checkOption('SuccessCard')) {
      return (
        <Row>
          <Col md={12}>
            <Card outline color="success" className="border">
              <CardHeader className="bg-transparent">
                <h5 className="my-0 text-success">
                  <i className="mdi mdi-check-all mr-3"></i>
                  {step.text}
                </h5>
              </CardHeader>
              <CardBody data-testid="success-card">
                {components}
                <CardTitle className="mt-0"></CardTitle>
                <CardText></CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    }
    if (step.options.checkOption('PrimaryCard')) {
      return (
        <Row>
          <Col md={12}>
            <Card outline color="primary" className="border">
              <CardHeader className="bg-transparent">
                <h5 className="my-0 text-primary">
                  <i className="mdi mdi-bullseye-arrow mr-3"></i>
                  {step.text}
                </h5>
              </CardHeader>
              <CardBody>{components}</CardBody>
            </Card>
          </Col>
        </Row>
      );
    }
    if (step.options.checkOption('ErrorCard')) {
      return (
        <Row>
          <Col md={12}>
            <Card outline color="danger" className="border">
              <CardHeader className="bg-transparent">
                <h5 className="my-0 text-danger">
                  <i className="mdi mdi-block-helper mr-3"></i>
                  {step.text}
                </h5>
              </CardHeader>
              <CardBody>{components}</CardBody>
            </Card>
          </Col>
        </Row>
      );
    }
    if (step.options.checkOption('Toolbar')) {
      return (
        <Row>
          <Col md={12}>
            <div className="button-items" style={{ paddingTop: '8px', paddingBottom: '18px' }}>
              {components}
            </div>
          </Col>
        </Row>
      );
    }
    if (step.options.checkOption('ButtonBar')) {
      return (
        <Row>
          <Col md={12}>
            <div
              className="btn-group btn-group-toggle"
              data-testid="button-bar"
              data-toggle="buttons"
            >
              {components}
            </div>
          </Col>
        </Row>
      );
    }
    if (step.options.checkOption('TabBar')) {
      return (
        <Row>
          <Col md={12}>
            <div className="tab-group tab-group-toggle" data-testid="tab-bar" data-toggle="tabs">
              {components}
            </div>
          </Col>
        </Row>
      );
    }
    if (step.options.checkOption('Bullets')) {
      return (
        <Row>
          <Col md={12}>
            <div style={{ paddingLeft: '40px', paddingTop: '8px', paddingBottom: '18px' }}>
              {components}
            </div>
          </Col>
        </Row>
      );
    }
    if (step.options.checkOption('ButtonMenu')) {
      return (
        <Row>
          <Col md={12}>
            <div className="button-menu">
              <table className="button-list-table table-nowrap table-centered table-borderless table">
                <tbody>{components}</tbody>
              </table>
            </div>
          </Col>
        </Row>
      );
    }
    if (step.options.checkOption('FormFieldGroup')) {
      return <></>;
    }
    return (
      <Row data-testid={'pagegroup'}>
        <Col md={12}>{components}</Col>
      </Row>
    );
  }
}
