import React, { Component } from 'react';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepDashStat, StringOptions } from '@lainaedge/platformshared';

/**
 * Visual primitives for the component age. Use the best bits of ES6 and CSS to style your apps without stress
 *
 * @remarks
 *
 * Take a look at official documentation:
 * {@link https://reactstrap.github.io/} or
 * {@link https://github.com/reactstrap/reactstrap}
 * @packageDocumentation
 */
import styled from 'styled-components';

import { RenderHelper } from '../RenderHelper';

const DashStat = styled.div`
  display: inline-block;
  word-wrap: break-word;
  background-clip: border-box;
  // border: 0 solid #f6f6f6;
  border-radius: 1rem;
  min-height: 1px;
  padding: 1px;
  margin-bottom: 24px;
  margin-right: 10px;
  cursor: pointer;
  //box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  text-align: center;

  h3 {
    background: white;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 10px 20px;
  }

  h4 {
    color: white;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`;

/**
 * Props for [[`PageDashStat`]] component
 */
export interface PageDashStatProps {
  /** Used run processTextReplacement. */
  logic: any;
  /** Step data */
  step: StepDashStat;
  /** Used for redirecting. */
  renderer: RenderHelper;
}

/**
 * PageDashStat component
 *
 * @component PageDashStat
 * @category PageElements
 */
export default class PageDashStat extends Component<PageDashStatProps> {
  constructor(props: PageDashStatProps) {
    super(props);

    this.state = {};
  }

  /**
   * DashStat click handler. Redirects to option 1 of the step data.
   */
  handleClick = async () => {
    const step = this.props.step;
    //window.location.href = step.raw.option_1!;
    this.props.renderer.navigate(step.raw.option_1);
  };

  /**
   * Renders PageDashStat class component.
   */
  public render(): JSX.Element {
    const step = this.props.step;
    const processedText = this.props.logic.processTextReplacement(step.text);
    const processedFieldID = this.props.logic.processTextReplacement(step.field_id);
    console.log(step);

    const opt = new StringOptions(step.option_2);

    let wrapperStyle = 'dash-stat';

    if (opt.checkOption('Primary')) wrapperStyle += ' btn-primary';
    else if (opt.checkOption('Secondary')) wrapperStyle += ' btn-secondary';
    else if (opt.checkOption('Danger')) wrapperStyle += ' btn-danger';
    else if (opt.checkOption('Warning')) wrapperStyle += ' btn-warning';
    else if (opt.checkOption('Info')) wrapperStyle += ' btn-info';
    else if (opt.checkOption('Light')) wrapperStyle += ' btn-light';
    else if (opt.checkOption('Dark')) wrapperStyle += ' btn-dark';
    else if (opt.checkOption('Success')) wrapperStyle += ' btn-success';
    else wrapperStyle += ' btn-default';

    return (
      <DashStat className={wrapperStyle} onClick={this.handleClick}>
        <h3 className="font-weight-medium">{processedText}</h3>
        <h4 className="mb-0">{step.count}</h4>
      </DashStat>
    );
  }
}
