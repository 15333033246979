import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  options: Array<any>;
  setSearch: (s: string) => void;
  setShowSearchResultBox: (b: boolean) => void;
};

const useOutsideClick = (callback: any) => {
  const ref: any = React.useRef();

  React.useEffect(() => {
    const handleClick = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref]);

  return ref;
};

const SearchResultBox = ({ options, setSearch, setShowSearchResultBox }: Props) => {
  const navigate = useNavigate();

  const handleCloseSearchResult = () => {
    setShowSearchResultBox(false);
  };

  const ref = useOutsideClick(handleCloseSearchResult);

  const handleClickOption = (uuid: string) => {
    navigate(`/page/participant_detail/${uuid}/0`);
    handleCloseSearchResult();
    setSearch('');
  };

  console.log(options);

  return (
    <div className="search-result-box p-3" ref={ref}>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={handleCloseSearchResult}
      >
        ×
      </button>
      {options.length > 0 ? (
        <div className="search-result-table-wrapper">
          <table className="table sortable-table table-striped">
            <thead>
              <tr>
                <th>Participant ID</th>
                <th>Participant Name</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {options.map((option: any) => {
                return (
                  <tr key={option._id}>
                    <td
                      className={option.highlight_field?.includes('uuid') ? 'bold' : ''}
                      onClick={() => handleClickOption(option.uuid)}
                    >
                      {option.uuid}
                    </td>
                    <td className={option.highlight_field?.includes('name') ? 'bold' : ''}>
                      {option.first_name} {option.last_name}
                    </td>
                    <td className={option.highlight_field?.includes('email') ? 'bold' : ''}>
                      {option.email_for_survey_panel}
                    </td>
                    <td>-</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div>No Result Found</div>
      )}
    </div>
  );
};

export default SearchResultBox;
