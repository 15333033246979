import React, { Component } from 'react';

/**
 * Stateless React Components for Bootstrap 4.
 *
 * @remarks
 *
 * Library contains React Bootstrap 4 components that favor composition and control.
 * Take a look at official documentation:
 * {@link https://reactstrap.github.io/} or
 * {@link https://github.com/reactstrap/reactstrap}
 * @packageDocumentation
 */
import { Table } from 'reactstrap';

/**
 * Imports specific from Laina Shared Platform
 */
import { DataRecord } from '@lainaedge/platformshared';

/**
 * Library to parse, validate, manipulate and display dates and times in JavaScript.
 *
 * @remarks
 *
 * Take a look at official documentation: {@link https://momentjs.com/}
 * @packageDocumentation
 */
import moment from 'moment';

import { myProjectName } from '../../../constants';
import { isJson } from '../../../helpers';
/**
 * Props for [[`FieldHistoryItem`]] component
 */
export interface FieldHistoryItemProps {
  /** Database records to be displayed. */
  records: DataRecord[];
  /** Lock history items to be displayed. */
  lockHistory: DataRecord[];
}

/**
 * State for [[`FieldHistoryItem`]] component
 */
export interface FieldHistoryItemState {
  /** If set to true shows all records, otherwise shows only records where reason text is not empty. */
  showAll: boolean;
}

/**
 * FieldHistoryItem component
 *
 * @component FieldHistoryItem
 * @category FormElements
 */
export default class FieldHistoryItem extends Component<
  FieldHistoryItemProps,
  FieldHistoryItemState
> {
  /**
   * Used to toggle between rendering all records or just those whose reason text is not empty.
   */
  state: FieldHistoryItemState = {
    showAll: false,
  };

  /**
   * Used to toggle showAll state. If set to true shows all records,
   * otherwise shows only records where reason text is not empty.
   *
   * @returns Promise
   */
  handleToggle = async () => {
    this.setState({ showAll: !this.state.showAll });
  };

  /**
   * Renders FieldHistoryItem class component.
   */
  public render(): JSX.Element {
    const lockDates = this.props.lockHistory
      .filter((r: DataRecord) => r.data.event_text.includes(' locked'))
      .map((r: DataRecord) => r.data.event_dt_local);

    const renderItems = this.props.records.filter((record: DataRecord) => {
      return record.data.reason_text !== '' || this.state.showAll;
    });

    const hiddenItemCount = this.props.records.length - renderItems.length;

    const jsonEncoded =
      myProjectName.toLowerCase() == 'demo' &&
      renderItems[0]?.data?.table_field == 'medication_name';

    return (
      <>
        <div className="table-responsive field-history-table">
          <Table className="table table-sm m-0">
            <thead>
              <tr>
                <th className="date">Date</th>
                <th className="user">User</th>
                <th>Old Value</th>
                <th>New Value</th>
                <th className="reason">Reason</th>
                <th style={{ textAlign: 'right' }}>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      id="{field.field}"
                      className="custom-control-input"
                      checked={this.state.showAll}
                      onChange={this.handleToggle}
                    />
                    <label className="custom-control-label" onClick={this.handleToggle}>
                      Show All
                    </label>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {renderItems.length === 0 ? (
                <tr>
                  <td colSpan={5}>
                    <i> {hiddenItemCount} change(s) filtered </i>
                  </td>
                </tr>
              ) : (
                renderItems.map((record: DataRecord) => {
                  return (
                    <tr
                      key={record.id}
                      className={
                        moment(record.data.event_dt_local).isAfter(lockDates[lockDates.length - 1])
                          ? 'light-red-bg'
                          : ''
                      }
                    >
                      <td className="date" scope="row">
                        {moment(record.data.event_dt_local).format('YYYY-MM-DD')}
                      </td>
                      <td className="user">{record.data.reported_by}</td>
                      <td>
                        {isJson(record.data.old_value)
                          ? JSON.parse(record.data.old_value)?.name
                          : record.data.old_value}
                      </td>
                      <td>
                        {isJson(record.data.new_value)
                          ? JSON.parse(record.data.new_value)?.name
                          : record.data.new_value}
                      </td>
                      <td>{record.data.reason_text}</td>
                      <td></td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}
