import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Modal, Form, InputGroup } from 'reactstrap';
import { useData } from '../../context/DataContext';
import { saveBookmark } from '../../services';
import { myProjectName } from '../../constants';

/**
 * BookmarkModal component.
 *
 * @remarks
 * Renders form to input bookmark title
 *
 * @component BookmarkModal
 * @category Component
 */
const BookmarkModal = (): JSX.Element => {
  const location = useLocation();
  const [cookies] = useCookies();
  const { showBookmarkModal, setShowBookmarkModal, setBookmark, bookmarkTitle } = useData();

  const [title, setTitle] = useState<string>('');
  const [error, setError] = useState('');

  useEffect(() => {
    setTitle(bookmarkTitle);
  }, [bookmarkTitle]);

  const handleSave = () => {
    const authUser = cookies['authUser'];
    saveBookmark(authUser.username, location.pathname, title, myProjectName.toLowerCase());
    setBookmark(true);
    handleClose();
  };

  const handleClose = () => {
    setShowBookmarkModal(false);
    setTitle('');
  };

  return (
    <Modal size="lg" isOpen={showBookmarkModal}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Bookmark this page</h5>
        <button
          type="button"
          onClick={handleClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div id="query-form" className="twitter-bs-wizard">
          <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
              e.preventDefault();
            }}
          >
            <div className="col-md-12">
              <label className={'col-md-12 col-form-label'}>Title:</label>
              <div className="col-md-12">
                <InputGroup>
                  <input
                    className={'form-control'}
                    name={'title'}
                    value={title}
                    type="text"
                    onChange={(e: any): void => {
                      setTitle(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
              <div className="col-md-12">
                {error && <span className="validation-error">{error}</span>}
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary waves-effect waves-light"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </Modal>
  );
};
export default BookmarkModal;
