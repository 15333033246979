import React from 'react';

import {
  StepText,
  StepBreak,
  StepButton,
  StepGroup,
  StepSection,
  StepDashStat,
  StepDashButton,
  StepTable,
  StepTabs,
  StepForm,
  StepList,
  StepBase,
  PageStepType,
  PageRenderBase,
} from '@lainaedge/platformshared';

import {
  PageText,
  PageTable,
  PageGrid,
  PageButton,
  PageForm,
  PageGroup,
  PageSection,
  PageList,
  PageListItem,
  PageDashStat,
  PageDashButton,
  PageTrace,
} from './PageElements';

import { printEDCLog } from '../helpers';
import { FormFieldGroup } from './PageElements/FormElements/types';

/**
 * RenderHelper component.
 *
 * @remarks
 * RenderHelper that prepares the components for render
 *
 * @component RenderHelper
 * @category Component
 */
export class RenderHelper extends PageRenderBase {
  /**
   * When working with a "tree" of components we are always
   * adding to one branch.  That is a JSX.Element[] list.
   * We keep a list of branches so that when we are done with
   * the current one, we can go back to the previous
   **/
  componentTargets: (JSX.Element | StepForm.FieldInfo | FormFieldGroup)[][] = [];
  currentTarget: (JSX.Element | StepForm.FieldInfo | FormFieldGroup)[] = [];
  branches: string[] = [];

  currentList: JSX.Element[] | undefined;
  currentListItem: any | undefined;
  currentListItems: any[] | undefined;
  navigate: any;
  location: any;
  context: any;
  isInForm = false;

  taskListOptions: any = {
    taskListSort: '',
  };

  /** When starting a new group or collection of components,
   * use pushBranch to start a new list of components */
  pushBranch(name: string) {
    // Comment this line in production, name is just used for debugging
    // printEDCLog('* Adding elements to a new branch named :', name);
    // Save the current target to the array
    this.componentTargets.push(this.currentTarget);
    // Create a new target for components to be added to
    this.currentTarget = [];
    // Set current Branch name
    this.branches.push(name);
  }

  /** When finished with a list or group then use this to go back
   * to the previous target
   * @return The current list of elements
   */
  popBranch(): (JSX.Element | StepForm.FieldInfo | FormFieldGroup)[] {
    // Current branch is done, return it to the caller and hope that
    // it used for something.
    const list: (JSX.Element | StepForm.FieldInfo | FormFieldGroup)[] = [];
    this.currentTarget.forEach((e) => {
      list.push(e);
    });

    const top = this.componentTargets.pop();
    if (top) this.currentTarget = top;
    else this.currentTarget = [];

    this.branches.pop();

    return list;
  }

  addComponent(component: JSX.Element) {
    if (this.currentList) {
      this.currentList.push(component);
    } else {
      this.currentTarget.push(component);
    }
  }

  renderText(opt: StepText): void {
    //printEDCLog('renderText', opt);
    const text = opt.text;
    const type = opt.options.value;
    const lastBranch = this.branches.length ? this.branches[this.branches.length - 1] : '';

    if (this.currentListItem) {
      /** For Task list ( Participant Detail Page ) */
      this.currentListItem[type] = text;
    } else {
      if (type === 'Breadcrumbs') {
        const emptyBreadCrumb = text[0] === '/';
        const location = this.location;

        if (emptyBreadCrumb) {
          /** Set default bookmark title for bookmark modal */
          this.context.setBookmarkTitle(text.substring(1));

          if (
            this.context.breadCrumbs.length == 1 &&
            this.context.breadCrumbs[0].text == text.substring(1)
          ) {
            // Prevent infinite reloading on the same page
            return;
          }
          this.context.setBreadCrumbs([
            {
              text: text.substring(1),
              link: location.pathname,
            },
          ]);
        } else {
          /** Set default bookmark title for bookmark modal */
          this.context.setBookmarkTitle(text);

          if (!this.context.breadCrumbs.map((item: any) => item.text).includes(text)) {
            // Prevent infinite reloading on the same page, because the same item already exists in the array
            return this.context.setBreadCrumbs([
              ...this.context.breadCrumbs,
              {
                text: text,
                link: location.pathname,
              },
            ]);
          } else {
            const currentIndex = this.context.breadCrumbs
              .map((item: any) => item.text)
              .indexOf(text);
            if (currentIndex === this.context.breadCrumbs.length - 1) {
              // If the item is the last index, don't do anything
              return;
            }
            //Remove the items after the current index
            this.context.setBreadCrumbs(this.context.breadCrumbs.slice(0, currentIndex + 1));
          }
        }
      } else {
        this.addComponent(
          <PageText
            logic={this.logicRef}
            key={opt.key_id}
            step={opt}
            wrapper={lastBranch}
          ></PageText>,
        );
      }
    }
  }

  renderTrace(opt: StepText): void {
    // printEDCLog('renderTrace', opt);
    this.addComponent(<PageTrace logic={this.logicRef} key={opt.key_id} step={opt}></PageTrace>);
  }

  renderBreak(opt: StepBreak): void {
    this.addComponent(
      <div className="mt-1" key={opt.key_id}>
        &nbsp;
      </div>,
    );
  }

  renderButton(opt: StepButton): void {
    //printEDCLog('renderButton', opt);
    const actionUrl = this.logicRef?.processTextReplacement(opt.raw.option_1);
    const lastBranch = this.branches.length ? this.branches[this.branches.length - 1] : '';

    this.addComponent(
      <PageButton
        logic={this.logicRef}
        key={opt.key_id}
        wrapper={lastBranch}
        step={opt}
        renderer={this}
        targetAction={actionUrl ? actionUrl : ''}
      ></PageButton>,
    );
  }

  startSection(opt: StepSection): void {
    // printEDCLog('startSection', opt);
    this.pushBranch('StartSection');
  }

  endSection(opt: StepSection): void {
    //printEDCLog('endSection', opt);
    const currentSection = this.popBranch();
    /** Add each component to the list with a View wrapper that has the percent set */
    const list: JSX.Element[] = [];
    currentSection.forEach((item) => {
      if (item.hasOwnProperty('key')) {
        list.push(
          <React.Fragment key={'group-item' + (item as JSX.Element).key}>{item}</React.Fragment>,
        );
      } else {
        console.warn('endGroup unexpected issue with non JSX Element in group.');
      }
    });

    const group = <PageSection key={opt.key_id} step={opt} components={list}></PageSection>;
    this.addComponent(group);
  }

  startGroup(opt: StepGroup): void {
    printEDCLog('startGroup', opt);
    if (opt.options.checkOption('ButtonMenu')) {
      this.pushBranch('ButtonMenu');
    } else if (opt.options.checkOption('TabBar')) {
      this.pushBranch('TabBar');
    } else {
      this.pushBranch('StartGroup');
    }
  }

  endGroup(opt: StepGroup): void {
    const currentGroup = this.popBranch();
    /** Add each component to the list with a View wrapper that has the percent set */
    const list: JSX.Element[] = [];
    const listInForm: (StepForm.FieldInfo | JSX.Element)[] = [];

    currentGroup.forEach((item) => {
      if (item.hasOwnProperty('key')) {
        if (this.isInForm) {
          listInForm.push(
            <React.Fragment key={'group-item' + (item as JSX.Element).key}>{item}</React.Fragment>,
          );
        } else {
          list.push(
            <React.Fragment key={'group-item' + (item as JSX.Element).key}>{item}</React.Fragment>,
          );
        }
      } else {
        if (this.isInForm) {
          listInForm.push(item as StepForm.FieldInfo);
        }
        console.warn('endGroup unexpected issue with non JSX Element in group.');
      }
    });

    if (this.isInForm) {
      if (opt.options.checkOption('ButtonMenu')) {
        this.currentTarget.push({
          key: opt.key_id,
          type: 'ButtonMenu',
          children: listInForm,
        });
      } else {
        this.currentTarget.push({
          key: opt.key_id,
          type: 'Group',
          children: listInForm,
        });
      }
      return;
    }
    const group = <PageGroup key={opt.key_id} step={opt} components={list}></PageGroup>;
    this.addComponent(group);
  }

  renderDashStat(opt: StepDashStat): void {
    this.addComponent(
      <PageDashStat
        key={opt.key_id}
        logic={this.logicRef}
        step={opt}
        renderer={this}
      ></PageDashStat>,
    );
  }

  renderDashButton(opt: StepDashButton): void {
    this.addComponent(
      <PageDashButton
        key={opt.key_id}
        logic={this.logicRef}
        step={opt}
        renderer={this}
      ></PageDashButton>,
    );
  }

  renderUnknown(opt: PageStepType): void {
    printEDCLog('renderUnknown', opt);
  }

  renderTable(opt: StepTable): void {
    //printEDCLog('renderTable', opt);
    if (opt.option_1 === 'DataGrid') {
      this.addComponent(<PageGrid key={opt.key_id} step={opt} navigate={this.navigate}></PageGrid>);
    } else {
      this.addComponent(<PageTable key={opt.key_id} step={opt}></PageTable>);
    }
  }

  renderTabs(opt: StepTabs): void {
    printEDCLog('renderTabs', opt);
  }

  renderFormStart(opt: StepForm): void {
    printEDCLog('RenderHelper FormStart Opt=', opt);
    this.isInForm = true;
    opt.fields.forEach((e) => {
      if (!e.type.checkOption('Button')) {
        printEDCLog('RenderHelper FormStart Button Value=', opt.getValueDatabase(e.field));
      }
    });
    this.pushBranch('Form Start');
  }

  renderFormField(opt: StepForm, field: StepForm.FieldInfo) {
    /** Add the form field to the current list of compontents */
    this.currentTarget.push(field);
  }

  renderFormEnd(opt: StepForm): void {
    const itemList = this.popBranch();
    this.isInForm = false;
    this.addComponent(
      <PageForm key={opt.key_id} step={opt} items={itemList} renderer={this}></PageForm>,
    );
  }

  // async handleSubmitForm(): Promise<FieldError[]> {
  //   return await this.submitForm();
  // }

  renderListStart(opt: StepList): void {
    this.currentList = [];
    this.currentListItems = [];
  }

  renderListEnd(opt: StepList): void {
    //printEDCLog('renderListEnd');
    if (this.currentList) {
      /** Add each component to the list with a View wrapper that has the percent set */

      // printEDCLog('RenderHelper renderListEnd', this.currentList);

      const list: JSX.Element[] = [];
      this.currentList.forEach((item, i) => {
        list.push(<React.Fragment key={'list-item' + i}>{item}</React.Fragment>);
      });

      const group = (
        <PageList
          key={opt.key_id}
          step={opt}
          components={list}
          items={this.currentListItems}
          renderer={this}
        ></PageList>
      );
      this.currentList = undefined;
      this.currentListItems = undefined;
      this.addComponent(group);
    }
  }

  renderListItemStart(opt: StepList): void {
    //printEDCLog('renderListItemStart', opt);

    this.currentListItem = {
      TaskIcon: '',
      TaskTitle: '',
      lockstatus: '',
      TaskSubTitle: '',
      TaskStatus: '',
      click: '',
      style: '',
      hidelocks: '',
    };
    for (const key of Object.keys(opt.listOptions)) {
      this.currentListItem[key] = opt.listOptions[key];
    }
  }

  renderListItemEnd(opt: StepList): void {
    // printEDCLog('this.currentListItem', this.currentListItem);
    //printEDCLog('renderListItemEnd');
    if (this.currentListItem) {
      const item = (
        <PageListItem
          key={opt.key_id}
          item={this.currentListItem}
          renderer={this}
          step={opt}
        ></PageListItem>
      );
      this.addComponent(item);
      this.currentListItems?.push(this.currentListItem);
    }
    this.currentListItem = undefined;
  }

  debugForm(variableName: string, dataValue?: any | undefined) {
    console.log('debugForm', variableName, dataValue);
  }

  debugMessage(message: string) {
    console.log('debugMessage', message);
  }

  debugStep(opt: StepBase) {
    console.log('debugStep', opt);
  }
}
