import React, { Component } from 'react';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepButton, StringOptions } from '@lainaedge/platformshared';
import styled from 'styled-components';

import { RenderHelper } from '../RenderHelper';

const ButtonMenuItem = styled.tr`
  cursor: pointer;
`;

/**
 * Props for [[`PageButton`]] component
 */
export interface PageButtonProps {
  /** Step data */
  step: StepButton;
  /** Logic for the button. */
  logic: any;
  /** Used for redirecting. */
  renderer: RenderHelper;
  /** String used for redirecting.
   *
   * @example
   * "page/enroll"
   */
  targetAction: string;
  /** Wrapper type */
  wrapper: string;
}

/**
 * PageButton component.
 *
 * @remarks
 * Main action button of the page.
 *
 * @component PageButton
 * @category PageElements
 */
export default class PageButton extends Component<PageButtonProps> {
  /**
   * Button click handler.
   *
   * @remarks
   * Used for redirecting to targetAction.
   */
  handleClick = async () => {
    const step = this.props.step;
    if (step.options.checkOption('GoToWebSite')) {
      window.location.href = this.props.targetAction;
    } else {
      this.props.renderer.navigate(this.props.targetAction);
    }
  };

  /**
   * Renders Button For ButtonMenu
   * @returns {Promise<JSX.Element>}
   */
  renderButtonForButtonMenu = () => {
    const step = this.props.step;

    const opt = new StringOptions(step.option_2);

    let textColor = '';

    if (opt.checkOption('Primary')) textColor += ' text-primary';
    else if (opt.checkOption('Success')) textColor += ' text-success';
    else if (opt.checkOption('Secondary')) textColor += ' text-secondary';
    else if (opt.checkOption('Danger')) textColor += ' text-danger';
    else if (opt.checkOption('Warning')) textColor += ' text-warning';
    else if (opt.checkOption('Info')) textColor += ' text-info';
    else if (opt.checkOption('Light')) textColor += ' text-light';
    else if (opt.checkOption('Dark')) textColor += ' text-dark';

    const mainText = step.text.split(';;');

    let barColor = '';

    if (opt.checkOption('Primary')) barColor += ' bg-primary';
    else if (opt.checkOption('Success')) barColor += ' bg-success';
    else if (opt.checkOption('Secondary')) barColor += ' bg-secondary';
    else if (opt.checkOption('Danger')) barColor += ' bg-danger';
    else if (opt.checkOption('Warning')) barColor += ' bg-warning';
    else if (opt.checkOption('Info')) barColor += ' bg-info';
    else if (opt.checkOption('Light')) barColor += ' bg-light';
    else if (opt.checkOption('Dark')) barColor += ' bg-dark';
    else barColor += ' bg-default';

    return (
      <ButtonMenuItem onClick={this.handleClick}>
        <td className={barColor} style={{ width: '5px', padding: 0, margin: 0 }}></td>
        <td className={`noborder`}></td>
        <td className="text-right" style={{ width: '24px' }}>
          <span>
            <i className={step.option_2 + ' font-size-24'}></i>
          </span>
        </td>
        <td>
          <h5 className={`text-wrap mb-0 ${textColor}`}>{mainText[0]}</h5>
        </td>
        <td className="text-right due-date">
          <span className={`font-size-14 ${textColor}`}>{mainText[1]}</span>
        </td>
        <td style={{ width: '5px', padding: 0 }}>
          <span> &gt; </span>
        </td>
      </ButtonMenuItem>
    );
  };

  /**
   * Renders Button For TabBar
   * @returns {Promise<JSX.Element>}
   */
  renderButtonForTabBar = () => {
    const step = this.props.step;

    const opt = new StringOptions(step.option_2);

    console.log(step);

    let baseStyle = 'tab';

    if (opt.checkOption('Primary')) baseStyle += ' tab-primary';
    else if (opt.checkOption('Secondary')) baseStyle += ' tab-secondary';
    else if (opt.checkOption('Danger')) baseStyle += ' tab-danger';
    else if (opt.checkOption('Warning')) baseStyle += ' tab-warning';
    else if (opt.checkOption('Info')) baseStyle += ' tab-info';
    else if (opt.checkOption('Light')) baseStyle += ' tab-light';
    else if (opt.checkOption('Dark')) baseStyle += ' tab-dark';

    const count = this.props.logic?.processTextReplacement(step.option_2);
    console.log(count);

    return (
      <div className={baseStyle} onClick={this.handleClick}>
        <span className="mr-2"></span>
        {step.text}
        {count && <span className={'badge badge-pill badge-tab-active'}>{count}</span>}
        <span className="ml-2"></span>
        <div className="active-line"></div>
      </div>
    );
  };

  /**
   * Renders PageButton class component.
   */
  public render(): JSX.Element {
    const step = this.props.step;

    const opt = new StringOptions(step.option_2);

    /** Render Button For ButtonMenu */
    if (this.props.wrapper === 'ButtonMenu') {
      return this.renderButtonForButtonMenu();
    }

    /** Render Button For TabBar */
    if (this.props.wrapper === 'TabBar') {
      return this.renderButtonForTabBar();
    }

    /** Render Normal Button */
    let baseButtonStyle = 'btn w-xs waves-effect waves-light';

    if (opt.checkOption('Primary')) baseButtonStyle += ' btn-primary';
    else if (opt.checkOption('Secondary')) baseButtonStyle += ' btn-secondary';
    else if (opt.checkOption('Danger')) baseButtonStyle += ' btn-danger';
    else if (opt.checkOption('Warning')) baseButtonStyle += ' btn-warning';
    else if (opt.checkOption('Info')) baseButtonStyle += ' btn-info';
    else if (opt.checkOption('Light')) baseButtonStyle += ' btn-light';
    else if (opt.checkOption('Dark')) baseButtonStyle += ' btn-dark';
    else baseButtonStyle += ' btn-success';

    const isColorOptionn = [
      'Primary',
      'Secondary',
      'Danger',
      'Warning',
      'Info',
      'Light',
      'Dark',
    ].includes(step.option_2);

    return (
      <button
        type="button"
        className={opt.checkOption('Selected') ? baseButtonStyle + ' active' : baseButtonStyle}
        onClick={this.handleClick}
      >
        {step.option_2 && !isColorOptionn && (
          <i className={step.option_2 + ' btn-label label-icon mr-2'}></i>
        )}
        {step.text}
      </button>
    );
  }
}
