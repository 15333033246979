import React from 'react';
// import axios from 'axios';
import FormInput from './FormInput';
import { QNS_VALUE, UNAVAILABLE_VALUE } from '../../../constants';
import { InputProps } from './types';

/**
 * MedicationSuggest component @extends FormInput
 *
 * @component MedicationSuggest
 * @category FormElements
 */
export default class MedicationSuggest extends FormInput {
  constructor(props: InputProps) {
    super(props);

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    this.state = {
      myAlign: this.props.formProps.step.getValueAlign(field.field),
      myUnits: this.props.formProps.step.getValueUnits(field.field),
      myFieldValue: this.getValue(field),
      options: [],
    };
  }

  componentDidMount() {
    const medicationStr = this.props.formProps.step.getValueDatabase('medication_name');
    if (medicationStr) {
      try {
        const medication = JSON.parse(medicationStr);
        this.setState({ options: medication?.dose ?? [] });
      } catch (err) {}
    }
  }

  shouldComponentUpdate = (nextProps: any) => {
    if (this.props.medication != nextProps.medication) {
      const field = this.props.formProps.field;
      console.log('nextProps.medication', nextProps.medication);
      const doseOptions = nextProps.medication?.dose;
      if (doseOptions?.length > 0) {
        this.setState({ options: doseOptions, myFieldValue: '' });
        this.props.formProps.step.setValueFromUser(field.field, '');
      } else {
        this.setState({ options: [], myFieldValue: '' });
        this.props.formProps.step.setValueFromUser(field.field, '');
      }
    }
    return true;
  };

  /**
   * Used to change the value of a field.
   *
   * @param field - Points to the field.
   * @param val - New value to be assigned to the field.
   * @returns Void
   */
  handleSelect = (e: any) => {
    const field = this.props.formProps.field;

    if (this.isEditFieldOnModal()) {
      this.props.formProps.handleChangeEditValues(field, e.target.value);
    } else {
      this.handleChangeText(field, e);
    }
  };

  /**
   * Renders MedicationSuggest class component.
   */
  public render(): JSX.Element {
    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    let options = this.state.options ?? [];

    console.log(fieldValue, options);
    if (Object.prototype.toString.call(options) !== '[object Array]') {
      options = [options];
    }

    return (
      <>
        <div className="d-inline-block mr-2 mb-1">
          <select
            className="form-control drop-down"
            onChange={this.handleSelect}
            disabled={this.isFieldDisabled()}
            value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
          >
            <option value="">Select</option>
            {options.map((item: string) => (
              <option key={item} value={item?.toLowerCase()}>
                {item}
              </option>
            ))}
          </select>
        </div>
        {this.renderQnsAndUnavailableSwitches()}
      </>
    );
  }
}
