import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

//i18n
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { useCookies } from 'react-cookie';
import { useAuth } from 'context/AuthContext';
import { useData } from 'context/DataContext';
import { ReactComponent as ChevronDownIcon } from 'assets/images/icons/chevron-down.svg';
import LainaLogoIcon from 'assets/images/laina-logo-sm-blue.png';

const ProfileMenu = (): JSX.Element => {
  const { logout } = useAuth();
  const { setShowQuickMenu } = useData();
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [cookies] = useCookies();

  const [username, setUsername] = useState('Admin');

  const { t } = useTranslation();
  const authOrigin = cookies['authOrigin'];

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    const authUser = cookies['authUser'];
    if (authUser) {
      if (authUser.firstName || authUser.lastName) {
        setUsername(authUser.firstName + ' ' + authUser.lastName + ' (' + authUser.role + ') ');
      } else {
        setUsername(authUser.username);
      }
    }
  }, [cookies]);

  const handleClickQuickAccess = () => {
    setMenu(!menu);
    setShowQuickMenu(true);
  };

  const handleGoBackToHub = () => {
    window.location.href = 'https://uat.hub.lainaent.com/';
    // logout();
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown d-flex align-items-center"
          tag="button"
        >
          {/* <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" /> */}
          <i className="bx bx-user-circle font-size-24 d-xl-none" />
          <span className="d-none d-xl-inline-block ml-3 mr-2 font-size-15 font-weight-bold">
            {username}
          </span>
          <ChevronDownIcon />
        </DropdownToggle>
        {authOrigin == 'Hub' ? (
          <DropdownMenu right>
            <div className="dropdown-item back-hub-div" onClick={handleGoBackToHub}>
              <img src={LainaLogoIcon} alt="Hub Laina" />
              <span>{t('Go back to Laina Hub')}</span>
            </div>
          </DropdownMenu>
        ) : (
          <DropdownMenu right>
            <div className="dropdown-item" onClick={handleClickQuickAccess}>
              <i className="fa-thin fa-square-list font-size-16 align-middle mr-1"></i>
              <span>{t('Quick Menu')}</span>
            </div>
            <div className="dropdown-divider"></div>
            <Link to="/changepassword" className="dropdown-item" onClick={() => setMenu(!menu)}>
              <i className="bx bx-user font-size-16 align-middle mr-1" />
              <span>{t('Change Password')}</span>
            </Link>
            <div className="dropdown-item logout-div" onClick={handleLogout}>
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
              <span>{t('Logout')}</span>
            </div>
          </DropdownMenu>
        )}
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
